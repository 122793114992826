import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getNavigationSidebarProductsSettingResource } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { globalSettingsProductsAdvancedRoadmapsHierarchyRoute } from '@atlassian/jira-router-routes-legacy-global-settings-products-advanced-roadmaps-hierarchy-routes/src/index.tsx';

export const globalSettingsProductsAdvancedRoadmapsHierarchyRouteEntry = createEntry(
	globalSettingsProductsAdvancedRoadmapsHierarchyRoute,
	() => ({
		layout: globalSettingsLayout,

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProducts,
		},

		resources: getNavigationSidebarProductsSettingResource(),
	}),
);
