import {
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_WORKFLOWS,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_WORKFLOWS_ASSOCIATE,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';

export const legacyProjectSettingsWorkflowsRoute: InvariantRoute = {
	name: ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_WORKFLOWS,
	path: '/plugins/servlet/project-config/:projectKey/workflows',
};

export const legacyProjectSettingsWorkflowsAssociateRoute: InvariantRoute = {
	name: ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_WORKFLOWS_ASSOCIATE,
	path: '/secure/project/SelectProjectWorkflowScheme!default.jspa',
};
