import {
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_FIELDS,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_FIELDS_ASSOCIATE,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';

export const legacyProjectSettingsFieldsRoute: InvariantRoute = {
	name: ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_FIELDS,
	path: '/plugins/servlet/project-config/:projectKey/fields',
};

export const legacyProjectSettingsFieldsAssociateRoute: InvariantRoute = {
	name: ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_FIELDS_ASSOCIATE,
	path: '/secure/admin/SelectFieldLayoutScheme!default.jspa',
};
