import React, { Component } from 'react';
import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';
import { ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { SpaStateSubscriber } from '../../common/index.tsx';
import { getApdexMatchObject } from '../../utils/index.tsx';
import CustomTrigger from './custom-trigger.tsx';
import type { Props } from './types.tsx';

// eslint-disable-next-line jira/react/no-class-components
class SpaStateResetPage extends Component<Props> {
	shouldComponentUpdate(nextProps: Props) {
		const cleanMatch = getApdexMatchObject(this.props.match, this.props.route);
		const cleanNextMatch = getApdexMatchObject(nextProps.match, nextProps.route);
		return !isEqual(cleanMatch, cleanNextMatch);
	}

	render() {
		const { action, match, route, isSpa } = this.props;

		const shouldNotUpdate =
			action === 'REPLACE' && (!isSpa || route.name === ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR);

		return (
			/* TODO - this should be using only the actions subscriber not state+actions subscriber */
			<SpaStateSubscriber>
				{(_, { resetCurrentPage }) => (
					<CustomTrigger
						// @ts-expect-error - TS2322 - Type '(...args: any[]) => void' is not assignable to type '() => Action<State, void, void | Promise<void>>'.
						resetCurrentPage={shouldNotUpdate ? noop : resetCurrentPage}
						match={getApdexMatchObject(match, route)}
						route={route}
					/>
				)}
			</SpaStateSubscriber>
		);
	}
}

export default SpaStateResetPage;
