import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	changeToOldEditorTitle: {
		id: 'workflow-editor-preference-settings.controllers.change-preference-flag.change-to-old-editor-title',
		defaultMessage: "You've changed the default editor",
		description:
			'Title for success flag when user successfully updated their editor preference to old editor',
	},
	changeToOldEditorDescription: {
		id: 'workflow-editor-preference-settings.controllers.change-preference-flag.change-to-old-editor-description',
		defaultMessage:
			'You can use the old editor as needed while we continue building the new editor.',
		description:
			'Description for success flag when user successfully updated their editor preference to old editor',
	},
	learnMore: {
		id: 'workflow-editor-preference-settings.controllers.change-preference-flag.learn-more',
		defaultMessage: 'Learn more',
		description: 'Label for learn more link in success flag',
	},
	switchToNewEditor: {
		id: 'workflow-editor-preference-settings.controllers.change-preference-flag.switch-to-new-editor',
		defaultMessage: 'Switch to new editor',
		description: 'Label for switch to new editor link in success flag',
	},
	changeToNewEditorTitle: {
		id: 'workflow-editor-preference-settings.controllers.change-preference-flag.change-to-new-editor-title',
		defaultMessage: 'Welcome to the new workflow editor',
		description:
			'Title for success flag when user successfully updated their editor preference to new editor',
	},
	changeToNewEditorDescription: {
		id: 'workflow-editor-preference-settings.controllers.change-preference-flag.change-to-new-editor-description',
		defaultMessage:
			'Enjoy a faster, cleaner, more intuitive experience while creating and editing your workflows.',
		description:
			'Description for success flag when user successfully updated their editor preference to new editor',
	},
	ok: {
		id: 'workflow-editor-preference-settings.controllers.change-preference-flag.ok',
		defaultMessage: 'Ok',
		description: 'Label for ok button in success flag',
	},
	updatePreferenceErrorFlagTitle: {
		id: 'workflow-editor-preference-settings.controllers.change-preference-flag.update-preference-error-flag-title',
		defaultMessage: "We couldn't update your default editor",
		description:
			"Title for error flag when there was a failure updating the user's editor preference",
	},
	updatePreferenceErrorFlagDescription: {
		id: 'workflow-editor-preference-settings.controllers.change-preference-flag.update-preference-error-flag-description',
		defaultMessage: 'Select Confirm to try again.',
		description:
			"Description for error flag when there was a failure updating the user's editor preference",
	},
});
