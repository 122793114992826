import { useEffect, useMemo, useState } from 'react';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import {
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { type EditorType, NEW_EDITOR } from '../../common/types.tsx';
import { getUserWorkflowEditorPreference } from '../../services/get-workflow-editor-preference/index.tsx';

export const useNewWorkflowEditorPreference = () => {
	const [editorType, setEditorType] = useState<EditorType | null>();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		getUserWorkflowEditorPreference()
			.then((pref) => {
				setEditorType(pref);

				fireOperationalAnalytics(
					createAnalyticsEvent({}),
					'getDefaultWorkflowEditorPreferance success',
				);
			})
			.catch((error) => {
				setEditorType(null);
				log.safeErrorWithoutCustomerData(
					'workflow-Editor.preference',
					'Unable to fetch workflow editor user preference',
					error,
				);

				fireErrorAnalytics({
					meta: {
						id: 'getDefaultWorkflowEditorPreference',
						packageName: 'jiraWorkflowEditorPreferenceSettings',
					},
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					error: error as Error,
					attributes: {
						isClientFetchError: isClientFetchError(error),
					},
				});
			});
	}, [createAnalyticsEvent]);

	const shouldUseNewWorkFlowEditor = useMemo(() => editorType === NEW_EDITOR, [editorType]);

	return { shouldUseNewWorkFlowEditor, userPreference: editorType };
};
