import { ROUTE_NAMES_LEGACY_BOARD } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types.tsx';

export const legacyBoardRoute: InvariantRoute = {
	name: ROUTE_NAMES_LEGACY_BOARD,
	path: '/secure/RapidBoard.jspa',
	query: ['rapidView'],
};

export const legacyBoard1Route: InvariantRoute = {
	name: ROUTE_NAMES_LEGACY_BOARD,
	path: '/secure/RapidBoard.jspa',
};
