import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { globalSettingsSystemGlobalPermissionsRoute } from '@atlassian/jira-router-routes-legacy-global-settings-system-global-permissions-routes/src/index.tsx';

export const globalSettingsSystemGlobalPermissionsRouteEntry = createEntry(
	globalSettingsSystemGlobalPermissionsRoute,
	() => ({
		layout: globalSettingsLayout,
		resources: [navigationSidebarGlobalResource],

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS,
		},
	}),
);
