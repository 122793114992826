import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsIssues from '@atlassian/jira-navigation-apps-sidebar-global-settings-issues/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { globalSettingsIssuesCustomFieldsTranslateRoute } from '@atlassian/jira-router-routes-legacy-global-settings-issues-custom-fields-translate-routes/src/index.tsx';

export const globalSettingsIssuesCustomFieldsTranslateRouteEntry = createEntry(
	globalSettingsIssuesCustomFieldsTranslateRoute,
	() => ({
		layout: globalSettingsLayout,

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_ISSUES_SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsIssues,
		},

		resources: [navigationSidebarGlobalResource],
	}),
);
