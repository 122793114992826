import {
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_SCREENS,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_SCREENS_ASSOCIATE,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';

export const legacyProjectSettingsScreensRoute: InvariantRoute = {
	name: ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_SCREENS,
	path: '/plugins/servlet/project-config/:projectKey/screens',
};

export const legacyProjectSettingsScreensAssociateRoute: InvariantRoute = {
	name: ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_SCREENS_ASSOCIATE,
	path: '/secure/project/SelectIssueTypeScreenScheme!default.jspa',
};
