import {
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_NOTIFICATIONS,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_NOTIFICATIONS_ASSOCIATE,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';

export const legacyProjectSettingsNotificationsRoute: InvariantRoute = {
	name: ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_NOTIFICATIONS,
	path: '/plugins/servlet/project-config/:projectKey/notifications',
};

export const legacyProjectSettingsNotificationsAssociateRoute: InvariantRoute = {
	name: ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_NOTIFICATIONS_ASSOCIATE,
	path: '/secure/project/SelectProjectScheme!default.jspa',
};
