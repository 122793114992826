// like !default / !edit / %21viewLogs
import { ROUTE_NAMES_LEGACY_REPORTS_CONFIG } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';

const defaultSuffix = '([!%]?[\\w]*)';

export const legacyReportsConfigRoute: InvariantRoute = {
	name: ROUTE_NAMES_LEGACY_REPORTS_CONFIG,
	path: `/secure/ConfigureReport${defaultSuffix}.jspa`,
	query: ['reportKey'],
};
