import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { EditorType } from '../../common/types.tsx';

const workflowEditorPreferenceKey = 'jira.user.workflow.editor.global.scope.enrolment';

const WORKFLOW_EDITOR_PREFERENCE_QUERY = `
query WorkflowEditorPreferenceQuery {
    myPreferences(keys: [
        "${workflowEditorPreferenceKey}"
    ])
}
`;

export const getUserWorkflowEditorPreference = async (): Promise<EditorType | null> => {
	const apiResult = await performPostRequest('/rest/gira/1/', {
		body: JSON.stringify({
			query: WORKFLOW_EDITOR_PREFERENCE_QUERY,
		}),
	});

	const preferences = apiResult?.data?.myPreferences;
	if (preferences) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return preferences[`${workflowEditorPreferenceKey}`] as EditorType;
	}

	const errorResponse = apiResult?.errors;
	if (errorResponse) {
		throw new Error(`Error getting user preference: ${workflowEditorPreferenceKey}`, {
			cause: apiResult?.errors?.[0] ?? 'unknown',
		});
	}

	return null;
};
