import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { legacyDashboardsDeleteRoute } from '@atlassian/jira-router-routes-legacy-dashboards-delete-routes/src/index.tsx';

export const legacyDashboardsDeleteRouteEntry = createEntry(legacyDashboardsDeleteRoute, () => ({
	layout: globalLayout,

	navigation: {
		menuId: MENU_ID.DASHBOARDS,
	},
}));
