import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { globalSettingsIssuesSecurityLevelAddRoute } from '@atlassian/jira-router-routes-legacy-global-settings-issues-security-level-add-routes/src/index.tsx';

export const globalSettingsIssuesSecurityLevelAddRouteEntry = createEntry(
	globalSettingsIssuesSecurityLevelAddRoute,
	() => ({
		layout: globalSettingsLayout,
		resources: [navigationSidebarGlobalResource],

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_ISSUES_SETTINGS,
		},
	}),
);
