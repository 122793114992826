import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { legacyDashboardsEditRoute } from '@atlassian/jira-router-routes-legacy-dashboards-edit-routes/src/index.tsx';

export const legacyDashboardsEditRouteEntry = createEntry(legacyDashboardsEditRoute, () => ({
	layout: globalLayout,

	navigation: {
		menuId: MENU_ID.DASHBOARDS,
	},
}));
