// like !default / !edit / %21viewLogs
import { ROUTE_NAMES_LEGACY_REPORTS_WORKLOAD_PIE } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';

const defaultSuffix = '([!%]?[\\w]*)';

export const legacyReportsWorkloadPieRoute: InvariantRoute = {
	name: ROUTE_NAMES_LEGACY_REPORTS_WORKLOAD_PIE,
	path: `/secure/ConfigureReport${defaultSuffix}.jspa`,
	query: ['reportKey=com.atlassian.jira.ext.charting:workloadpie-report'],
};
