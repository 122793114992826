import React from 'react';
import { createPortal } from 'react-dom';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { LegacyPage } from '@atlassian/jira-router-routes-common/src/ui/legacy-page/main.tsx';
import { WorkflowEditorPreferenceDropdown } from '@atlassian/jira-workflow-editor-preference-settings/src/ui/index.tsx';
import type { RouteContext } from '@atlassian/react-resource-router';

type Props = RouteContext;

export const LegacyWorkflowDesigner = (props: Props) => {
	const elm = __SERVER__
		? null
		: globalThis.document?.getElementById('workflow-editor-preference-dropdown');
	const workflowId = elm?.getAttribute('data-workflow-id') ?? '';
	const workflowName = elm?.getAttribute('data-workflow-name') ?? '';
	const editMode = elm?.getAttribute('data-edit-mode') ?? undefined;

	return (
		<JSErrorBoundary
			id="jsm-dropdown-error-boundary"
			packageName="jiraRouterRoutesLegacyWorkflowDesignerEntries"
			teamName="jsd-shield"
			fallback="flag"
		>
			<LegacyPage {...props} />
			{elm &&
				createPortal(
					<WorkflowEditorPreferenceDropdown
						workflowId={workflowId}
						workflowName={workflowName}
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						editMode={editMode as 'draft' | 'live'}
					/>,
					elm,
				)}
		</JSErrorBoundary>
	);
};
