import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsSystem from '@atlassian/jira-navigation-apps-sidebar-global-settings-system/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { globalSettingsSystemRolesDeleteRoute } from '@atlassian/jira-router-routes-legacy-global-settings-system-roles-delete-routes/src/index.tsx';

export const globalSettingsSystemRolesDeleteRouteEntry = createEntry(
	globalSettingsSystemRolesDeleteRoute,
	() => ({
		layout: globalSettingsLayout,

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsSystem,
		},

		resources: [navigationSidebarGlobalResource],
	}),
);
