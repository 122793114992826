import { ROUTE_NAMES_LEGACY_GIN_SUBSCRIPTIONS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types.tsx';

const secure = '(/secure/|/)';
const defaultSuffix = '([!%]?[\\w]*)'; // like !default / !edit / %21viewLogs

export const legacyGinSubscriptionsRoute: InvariantRoute = {
	name: ROUTE_NAMES_LEGACY_GIN_SUBSCRIPTIONS,
	path: `${secure}ViewSubscriptions${defaultSuffix}.jspa`,
};
