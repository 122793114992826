import globalCore, { type GlobalArgs } from './global-core.tsx';
import renderNavigation from './navigation-legacy/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { GlobalArgs };

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	install: (args?: Partial<GlobalArgs>) => {
		// @ts-expect-error - TS2322 - Type '{ flags?: boolean | undefined; navigation?: boolean | undefined; renderNavigation: (() => any) | (() => void); upflow?: boolean | undefined; heartbeat?: boolean | undefined; profileCardNextLegacy?: boolean | undefined; ... 4 more ...; searchDwellTimeComponent?: boolean | undefined; }' is not assignable to type 'GlobalArgs'.
		const argsWithDefaults: GlobalArgs = {
			renderNavigation,
			...args,
		};
		globalCore.install(argsWithDefaults);
	},
};
