// like !default / !edit / %21viewLogs
import { ROUTE_NAMES_LEGACY_REPORTS_AVERAGE_AGE } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';

const defaultSuffix = '([!%]?[\\w]*)';

export const legacyReportsAverageAgeRoute: InvariantRoute = {
	name: ROUTE_NAMES_LEGACY_REPORTS_AVERAGE_AGE,
	path: `/secure/ConfigureReport${defaultSuffix}.jspa`,
	query: ['reportKey=com.atlassian.jira.plugin.system.reports:averageage-report'],
};
