import {
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_ISSUE_SECURITY,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_ISSUE_SECURITY_ASSOCIATE,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';

export const legacyProjectSettingsIssueSecurityRoute: InvariantRoute = {
	name: ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_ISSUE_SECURITY,
	path: '/plugins/servlet/project-config/:projectKey/issuesecurity',
};

const defaultSuffix = '([!%]?[\\w]*)'; // like !default / !edit / %21viewLogs
export const legacyProjectSettingsIssueSecurityAssociateRoute: InvariantRoute = {
	name: ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_ISSUE_SECURITY_ASSOCIATE,
	path: `/secure/project/SelectProjectIssueSecurityScheme${defaultSuffix}.jspa`,
};
