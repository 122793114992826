import React from 'react';
import SpaStateResetPage from '@atlassian/jira-spa-state-controller/src/components/reset-page/index.tsx';
import type { LegacyPageProps } from './types.tsx';

export const LegacyPage = (props: LegacyPageProps) => (
	<>
		<SpaStateResetPage
			match={props.match}
			action={props.action}
			route={props.route}
			isSpa={false}
		/>
	</>
);

export default LegacyPage;
