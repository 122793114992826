import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'workflow-editor-preference-settings.default-workflow-editor-dialog.title',
		defaultMessage: 'Select a default workflow editor',
		description: 'Heading for the default workflow modal.',
	},
	body: {
		id: 'workflow-editor-preference-settings.default-workflow-editor-dialog.body',
		defaultMessage:
			'While we continue building the new workflow editor, you can switch between editors as needed. When you select a default, we’ll always open your workflows in that editor.',
		description: 'Warning modal body text to explain changes will be lost on close.',
	},
	instruction: {
		id: 'workflow-editor-preference-settings.default-workflow-editor-dialog.instruction',
		defaultMessage: 'Select a default workflow editor',
		description: 'Instruction text to choose default editor.',
	},
	link: {
		id: 'workflow-editor-preference-settings.default-workflow-editor-dialog.link',
		defaultMessage: 'Learn more about editing workflows.',
		description: 'Link that open a page helping user understand more about editing workflows.',
	},
	learnMoreLink: {
		id: 'workflow-editor-preference-settings.default-workflow-editor-dialog.learn-more-link',
		defaultMessage: '<a>Learn more about editing workflows</a>.',
		description: 'Link that open a page helping user understand more about editing workflows.',
	},
	newWorkflowEditor: {
		id: 'workflow-editor-preference-settings.default-workflow-editor-dialog.new-workflow-editor',
		defaultMessage: 'New editor',
		description: 'Label of new editor option.',
	},
	oldWorkflowEditor: {
		id: 'workflow-editor-preference-settings.default-workflow-editor-dialog.old-workflow-editor',
		defaultMessage: 'Old editor',
		description: 'Label of old editor option.',
	},
	cancelButton: {
		id: 'workflow-editor-preference-settings.default-workflow-editor-dialog.cancel-button',
		defaultMessage: 'Cancel',
		description: 'Label for back button.',
	},
	confirmButton: {
		id: 'workflow-editor-preference-settings.default-workflow-editor-dialog.confirm-button',
		defaultMessage: 'Confirm',
		description: 'Label for confirm button.',
	},
	updateButton: {
		id: 'workflow-editor-preference-settings.default-workflow-editor-dialog.update-button',
		defaultMessage: 'Update',
		description: 'Label for update button.',
	},
	draftLossWarning: {
		id: 'workflow-editor-preference-settings.default-workflow-editor-dialog.draft-loss-warning',
		defaultMessage:
			'You’ve made changes to {workflowName}. To save those changes select {cancel}, then publish your draft workflow before continuing.',
		description:
			'A warning message informing users to publish their draft their workflow before switching workflow editors as otherwise the changes will not be reflected in the new editor.',
	},
	draftUnpublishedWarning: {
		id: 'workflow-editor-preference-settings.default-workflow-editor-dialog.draft-unpublished-warning',
		defaultMessage:
			'Publish your draft workflow before continuing so that your edits will be visible in the new workflow editor.',
		description:
			'A warning message informing users to publish their draft their workflow before switching workflow editors as otherwise the changes will not be reflected in the new editor.',
	},
	dirtyLossWarning: {
		id: 'workflow-editor-preference-settings.default-workflow-editor-dialog.dirty-loss-warning',
		defaultMessage:
			'You’ve made changes to {workflowName}. To save those changes select {cancel}, then {update} workflow before continuing.',
		description:
			'A warning message informing users to save any changes to their workflow before switching workflow editors as they will otherwise be lost.',
	},
});
