export const NEW_EDITOR = 'NEW' as const;
export const LEGACY_EDITOR = 'LEGACY' as const;

export type EditorType = typeof NEW_EDITOR | typeof LEGACY_EDITOR;

// TODO: go/restrict-enums
// eslint-disable-next-line no-restricted-syntax
export enum WorkflowPublicationStatus {
	None,
	Dirty,
	Draft,
	Live,
}
