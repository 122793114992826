const SHOW_NEW_EDITOR_SUCCESS_FLAG = 'SHOW_NEW_EDITOR_SUCCESS_FLAG';
const SHOW_OLD_EDITOR_ERROR_FLAG = 'SHOW_OLD_EDITOR_SUCCESS_FLAG';

// New editor success flag
const updateShowNewEditorSucccessFlag = (value: boolean) => {
	sessionStorage.setItem(SHOW_NEW_EDITOR_SUCCESS_FLAG, value.toString());
};

export const getShouldShowNewEditorSucccessFlag = () =>
	Boolean(sessionStorage.getItem(SHOW_NEW_EDITOR_SUCCESS_FLAG));

export const setShowNewEditorSuccessFlag = () => {
	updateShowNewEditorSucccessFlag(true);
};

export const clearShowNewEditorSucccessFlag = () => {
	sessionStorage.removeItem(SHOW_NEW_EDITOR_SUCCESS_FLAG);
};

// Old editor success flag
const updateShowOldEditorSucccessFlag = (value: boolean) => {
	sessionStorage.setItem(SHOW_OLD_EDITOR_ERROR_FLAG, value.toString());
};

export const getShouldShowOldEditorSucccessFlag = () =>
	Boolean(sessionStorage.getItem(SHOW_OLD_EDITOR_ERROR_FLAG));

export const clearShowOldEditorSucccessFlag = () => {
	sessionStorage.removeItem(SHOW_OLD_EDITOR_ERROR_FLAG);
};

export const setShowOldEditorSuccessFlag = () => {
	updateShowOldEditorSucccessFlag(true);
};
