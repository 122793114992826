import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { getNavigationSidebarGlobalSettingsResources } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import AsyncSidebarGlobalSettingsIssues from '@atlassian/jira-navigation-apps-sidebar-global-settings-issues/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { workflowDesignerRoute } from '@atlassian/jira-router-routes-legacy-workflow-designer-routes/src/index.tsx';
import { LegacyWorkflowDesigner } from './ui/index.tsx';

export const workflowDesignerRouteEntry = createEntry(workflowDesignerRoute, () => ({
	component: LegacyWorkflowDesigner,
	layout: globalSettingsLayout,

	navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_ISSUES_SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsIssues,
	},

	resources: [
		...getNavigationResources(),
		navigationSidebarGlobalResource,
		...getNavigationSidebarGlobalSettingsResources(),
	],
}));
