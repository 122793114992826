import React from 'react';
import { LegacyPage } from '@atlassian/jira-router-routes-common/src/ui/legacy-page/main.tsx';
import type { RouteContext } from '@atlassian/react-resource-router';

type AllProps = RouteContext;

export const LegacyEditPermissionsPage = (props: AllProps) => (
	<>
		<LegacyPage {...props} />
	</>
);

export default LegacyEditPermissionsPage;
