// like !default / !edit / %21viewLogs
import { ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_DELETE } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';

const secure = '(/secure/|/)';
const defaultSuffix = '([!%]?[\\w]*)';

export const legacyProjectSettingsDeleteRoute: InvariantRoute = {
	name: ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_DELETE,
	path: `${secure}project/DeleteProject${defaultSuffix}.jspa`,
	query: ['pid'],
};
