import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarFilters from '@atlassian/jira-navigation-apps-sidebar-filters/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { legacyGinManageRoute } from '@atlassian/jira-router-routes-legacy-gin-manage-routes/src/index.tsx';

export const legacyGinManageRouteEntry = createEntry(legacyGinManageRoute, () => ({
	layout: globalLayout,

	navigation: {
		menuId: MENU_ID.FILTERS,
		sidebar: AsyncSidebarFilters,
	},

	resources: [navigationSidebarGlobalResource],
}));
