import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	reasonPlaceholder: {
		id: 'workflow-editor-preference-settings.controllers.opt-out-feedback.reason-placeholder',
		defaultMessage: 'Select an option',
		description:
			'Placeholder of the dropdown element where users will be able to select a reason for opting out of the Workflow Editor',
	},
	reasonQuestion: {
		id: 'workflow-editor-preference-settings.controllers.opt-out-feedback.reason-question',
		defaultMessage: 'Missing features',
		description: 'A topic when providing feedback for missing features; option in dropdown.',
	},
	reasonBug: {
		id: 'workflow-editor-preference-settings.controllers.opt-out-feedback.reason-bug',
		defaultMessage: 'Bugs',
		description: 'A topic when providing feedback for buggy experience; option in dropdown.',
	},
	reasonComment: {
		id: 'workflow-editor-preference-settings.controllers.opt-out-feedback.reason-comment',
		defaultMessage: 'Prefer old editor',
		description:
			'A topic when leaving a comment that the old editor is preferred; option in dropdown.',
	},
	reasonOthers: {
		id: 'workflow-editor-preference-settings.controllers.opt-out-feedback.reason-others',
		defaultMessage: 'Other',
		description: 'A topic when providing feedback for generic reason; option in dropdown.',
	},
	fieldLabel: {
		id: 'workflow-editor-preference-settings.controllers.opt-out-feedback.field-label',
		defaultMessage: 'Add details to help us improve the workflow editor for everyone.',
		description:
			'Follow-up question after reason is given for changing default option to legacy workflow designer',
	},
	feedbackTitleDetails: {
		id: 'workflow-editor-preference-settings.controllers.opt-out-feedback.feedback-title-details',
		defaultMessage: 'Help us improve your experience with the new workflow editor.',
		description: 'Description of the feedback collector',
	},
	feedbackTitleDetailsQuestion: {
		id: 'workflow-editor-preference-settings.controllers.opt-out-feedback.feedback-title-details-question',
		defaultMessage: 'Let us know why you’re moving to the old editor',
		description: 'Description of the feedback collector options',
	},
	feedbackCancelButton: {
		id: 'workflow-editor-preference-settings.controllers.opt-out-feedback.feedback-cancel-button',
		defaultMessage: 'Skip step',
		description: 'Label for button that cancels sending feedback',
	},
});
