import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	openInNewEditor: {
		id: 'workflow-editor-preference-settings.open-in-new-editor',
		defaultMessage: 'Open workflow in new editor',
		description: 'Link that opens workflow in new workflow editor',
	},
	changeDefaultEditor: {
		id: 'workflow-editor-preference-settings.change-default-editor',
		defaultMessage: 'Change default editor',
		description:
			'Link that allows you to switch default workflow editor to the old workflow editor',
	},
	switchEditorButton: {
		id: 'workflow-editor-preference-settings.switch-editor-button',
		defaultMessage: 'Switch editor',
		description: 'Button that allows you to open dropdown with option to switch editor',
	},
});
